import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {useIntl, Link, FormattedMessage, navigate} from "gatsby-plugin-intl"

const Privacy = () => {
    const intl = useIntl();
    return (
        <Layout>
          <SEO title="PRIVACY" />

          <div className="white-container" id="legals">

            <h1 className="text-center dark-grey text-uppercase mb-5">{intl.formatMessage({ id: "privacy.title" })}</h1>

            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text1" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title2" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text2" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title3" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text3" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title4" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text4" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title5" })}</h2>
            <h3>{intl.formatMessage({ id: "privacy.subtitle1" })}</h3>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text5" })}} />
            <h3>{intl.formatMessage({ id: "privacy.subtitle2" })}</h3>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text6" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title6" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text7" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title7" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text8" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title8" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text9" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title9" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text10" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title10" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text11" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title11" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text12" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title12" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text13" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title13" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text14" })}} />

            <h2>{intl.formatMessage({ id: "privacy.title14" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "privacy.text15" })}} />
          </div>

        </Layout>
    )
}

export default Privacy
